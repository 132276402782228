<template>
  <div class="elite-tabs-wrapper-content">
    <div class="resume-header">
      <div class="resume-header-image">
        <img
          v-if="organization.logo"
          :src="`${organization.logo}`"
          class="argon-image"
          style="width: 100%"
        />
        <img v-else src="/img/add-image.svg" class="default" />
      </div>
      <div class="resume-header-text">
        <h3>{{ organization.name }}</h3>
        <ul>
          <li>{{ organization.email }}</li>
        </ul>
      </div>
    </div>
    <div class="resume-list">
      <div class="resume-list-inner">
        <dl class="width_1-2">
          <dt>{{ $t("COMMON.EXCERPT") }}</dt>
          <dd>
            <div v-html="organization.excerpt"></div>
          </dd>
        </dl>

        <dl class="width_1-2">
          <dt>
            {{ $t("COMMON.PHONE") }}
          </dt>
          <dd>
            {{
              organization.phone_type
                ? $t(`COMMON.PHONE_TYPE_${organization.phone_type}`)
                : null
            }}:
            {{ organization.phone }}
            {{
              organization.phone_extension
                ? ` (ext: ${organization.phone_extension})`
                : null
            }}
          </dd>
        </dl>

        <dl class="width_1-2">
          <dt>
            {{ $t("COMMON.OTHER_PHONES") }}
          </dt>
          <dd>
            <span
              v-for="(line, index) in organization.other_phones"
              :key="index"
            >
              {{ $t(`COMMON.PHONE_TYPE_${line.type}`) }}:
              {{ line.phoneNumber }}
              {{ line.extension ? ` (ext: ${line.extension})` : null }} <br />
            </span>
          </dd>
        </dl>

        <dl class="width_1-2">
          <dt>{{ $t("COMMON.ADDRESS") }}</dt>
          <dd>
            {{ organization.address }}
          </dd>
        </dl>

        <dl
          class="width_1-2"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)"
        >
          <dt>{{ $t("COMMON.RESELLER") }}</dt>
          <dd>
            <reseller :reseller="organization.reseller" />
          </dd>
        </dl>

        <dl
          class="width_1-2"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS)"
        >
          <dt>{{ $t("COMMON.OWNER") }}</dt>
          <dd>
            <object-link
              v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS)"
              :object="organization.owner"
            />
          </dd>
        </dl>

        <dl class="width_1-2">
          <dt>{{ $t("COMMON.DEFAULT_TAXES") }}</dt>
          <dd>
            <dl
              class="width_1-2"
              v-for="(tax, key) in organization.taxes"
              :key="key"
            >
              <dt>{{ tax.name }}</dt>
              <dd v-if="tax.type === TAX_TYPE_PERCENTAGE">{{ tax.value }} %</dd>
              <dd v-if="tax.type === TAX_TYPE_AMOUNT">
                {{ $formatCurrency(tax.value) }}
              </dd>
            </dl>
          </dd>
        </dl>

        <dl class="width_1-2">
          <dt>{{ $t("ORGANIZATIONS.CREATED_AT") }}</dt>
          <dd v-if="organization.created_at">
            {{ $timeZoneDateFormat(organization.created_at) }}
          </dd>
        </dl>

        <dl class="width_1-2">
          <dt>{{ $t("ORGANIZATIONS.UPDATED_AT") }}</dt>
          <dd v-if="organization.updated_at">
            {{ $timeZoneDateFormat(organization.updated_at) }}
          </dd>
        </dl>

        <div class="title">
          <h3>{{ $t("COMMON.BILLING_ADDRESS") }}</h3>
        </div>

        <billing-informations-view :billingInformations="organization" />
      </div>
    </div>
  </div>
</template>

<script>
import models from "@/constants/models";
import { TAX_TYPE_PERCENTAGE, TAX_TYPE_AMOUNT } from "@/constants/taxes";
import BillingInformationsView from "@/components/BillingInformationsView.vue";

export default {
  name: "organization-view-global",

  components: { BillingInformationsView },

  props: ["organization"],

  data() {
    return {
      models: models,
      TAX_TYPE_AMOUNT: TAX_TYPE_AMOUNT,
      TAX_TYPE_PERCENTAGE: TAX_TYPE_PERCENTAGE,
    };
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    organization(organization) {},
  },
};
</script>
